(<template>
  <div class="sign-in">
    <auth-tabs />
    <div class="sign-in__content-wrapper">
      <sign-in-form />
    </div>
  </div>
</template>)

<script>
  import AuthTabs from '@/components/auth_components/AuthTabs';
  import AuthSignInForm from '@/components/auth_components/AuthSignInForm';

  export default {
    components: {
      'auth-tabs': AuthTabs,
      'sign-in-form': AuthSignInForm
    }
  };
</script>

<style scoped>
  .sign-in {
    display: block;
    width: 100%;
  }

  .sign-in__content-wrapper {
    display: block;
    width: 100%;
    max-width: 270px;
    margin: 0 auto;
  }
</style>
